import React from 'react';
import { FormattedMessage } from 'react-intl';

import CarrierRegistrationForm from 'components/carrier-registration-form';
import Img from 'components/image';

import './styles.scss';

const headerImage1 = 'be-carrier/header_image_1.png';
const headerImage2 = 'be-carrier/header_image_2.png';

const BeCarrierHeader = () => (
  <div className="be-carrier-header">
    <div className="container">
      <div className="row be-carrier-header__row">
        <div className="col col-12 col-lg-6">
          <div className="row">
            <div className="col col-12">
              <h1 className="h1--display be-carrier-header__title">
                <FormattedMessage id="transporter.header.title" />
              </h1>
              <div className="font-style-body-large be-carrier-header__subtitle">
                <FormattedMessage id="transporter.header.subtitle" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-12">
              <div className="row">
                <div className="col col-10 col-lg-8 col-lg-6">
                  <div className="d-none d-lg-block be-carrier-header__background-element" />
                  <Img
                    alt="Header 1"
                    classNames="be-carrier-header__image"
                    src={headerImage1}
                  />
                </div>
                <div className="col col-12 col-lg-8 offset-lg-4 be-carrier-header__image-column">
                  <Img
                    alt="Header 2"
                    classNames="be-carrier-header__image be-carrier-header__image--bottom"
                    src={headerImage2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-lg-6 offset-lg-0 col-xl-5 offset-xl-1">
          <CarrierRegistrationForm
            classNames="be-carrier-header__carrier-registration"
          />
        </div>
      </div>
    </div>
  </div>
);

export default BeCarrierHeader;
