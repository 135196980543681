import React from 'react';
import { FormattedMessage } from 'react-intl';
import PhoneStepsSlider from 'components/phone-steps-sliders';

import stepSlide1Image from 'images/how-it-works/carrier-registration.png';
import stepSlide2Image from 'images/how-it-works/carrier-accept-orders.png';
import stepSlide3Image from 'images/how-it-works/carrier-pickup-and-dropoff.png';
import stepSlide4Image from 'images/how-it-works/carrier-payment.png';

import './styles.scss';

const STEPS_SLIDES = [
  {
    index: 1,
    title: 'transporter.start.s1.title',
    description: 'transporter.start.s1.description'
  },
  {
    index: 2,
    title: 'transporter.start.s2.title',
    description: 'transporter.start.s2.description'
  },
  {
    index: 3,
    title: 'transporter.start.s3.title',
    description: 'transporter.start.s3.description'
  },
  {
    index: 4,
    title: 'transporter.start.s4.title',
    description: 'transporter.start.s4.description'
  }
];

const PHONE_SLIDES = [
  stepSlide1Image,
  stepSlide2Image,
  stepSlide3Image,
  stepSlide4Image
];

const BeCarrierStart = () => {
  const stepsSlides = STEPS_SLIDES;
  const phoneSlides = PHONE_SLIDES;

  return (
    <div className="be-carrier-start">
      <div className="container">
        <h6 className="be-carrier-start__title">
          <FormattedMessage id="transporter.start.title" />
        </h6>
        <h2 className="h2--display be-carrier-start__description">
          <FormattedMessage id="transporter.start.description" />
        </h2>
        <PhoneStepsSlider
          phoneSlides={phoneSlides}
          stepsSlides={stepsSlides}
        />
      </div>
    </div>
  );
};

export default BeCarrierStart;
